//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.rc-time-picker {
  width: 100%;
}

.rc-time-picker-input {
  height: 47.5px !important;
  border-radius: 1rem !important;
  background-color: #f8f9fa !important;
  outline: none !important;
}

.rc-time-picker-clear-icon:after {
  display: none !important;
}
