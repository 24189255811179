.mainContainer {
    border-top: '1px solid gray';
}

.addVehicleContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.feildContainers {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;
}

.containerCard {
    margin-top: 10px;
}

.pTagMarginLeft {
    margin-left: 10px;
}

.addVehicleSelect {
    opacity: 1;
    height: 47.5px;
    line-height: 1.25;
   width: 100% !important;
    box-shadow: none !important;
    border: 1px solid #cecece !important;
   font-weight: 400 !important;
}

.uploadFiles {
    display: flex;
    justify-content: space-between;
}

.imageGrid {
    display: flex;
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
    align-items: start;

}

/* .imageGrid::-webkit-scrollbar {
    width: 10px;  /* Remove scrollbar space */
/* background: transparent;  */
/* Optional: just make scrollbar invisible
}*/
.closeSpanDiv {
    border-radius: 50%;
    border: 1px solid black;
    background-color: rgba(0, 0, 0, 0.8);
    position: relative;
    right: 20px;
    height: 20px;
    min-height: 20px;
    width: 20px;
    min-width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;
    margin-left: 23px;
}

.closeSpan {
    /* background-color: red; */
    font-size: 18px;
    color: white;
}

/*

twoFeildContainers: {
    display: 'flex',
    // backgroundColor: 'green',
    justifyContent: 'space-between',
    width: "63.33%",
    paddingTop: '10px'
},


*/

.inputBoxShadow {
    box-shadow: 'rgb(136 136 136) 0px 0px 1px 1px inset' !important;
    background: '#fff' !important
}

.col-xxl-6 {
    width: 100% !important;
}

.choose_file {
    padding: 12px 16px !important;

}

.mobile-view {
 
    display: inline-block !important;
}

/* .create-vehicle-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
} */

.image_grid {
    position: absolute;
    bottom: 50px;
}

.create-vehicle-heading {
    font-size: 18px;
}

.create_vehicle_inner {
    border-bottom: 2px solid #ebebeb;
    margin-top: 4px;
    padding: 0 0 30px 0;
}

.image_wrapper {
    margin-bottom: 20px;
}

.data_inner {
    height: 130px !important;
}

.textarea_inner {
    height: 130px !important;
}

.surcharge_box {
    display: flex !important;
    width: 100%;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    color: #323232;
    background-color: #f8f9fa;
    background-clip: padding-box;
    border: 1px solid #cecece !important;
    border-radius: 16px;

    padding: 1px;
}

.number_field {
    width: 50% !important;
    padding: 8px !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 16px 0 0 16px !important;
    background-color: #f8f9fa !important;

}

.number_field:focus {
    outline: 0;
}

.dropdown_field {
    width: 50% !important;
    padding: 0 !important;
    border: none !important;
    border-radius: 0 16px 16px 0!important;
    box-shadow: none !important;
    background-color: #f8f9fa !important;
    text-align: center !important;
}

.create-vehicle-wrapper {
    align-items: flex-end !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

}

.create-editrate-wrapper {
    align-items: flex-start !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.create-editperhour-wrapper {
    align-items: center !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.create-partner-wrapper {
    align-items: flex-end !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

}

.add-partner-btn {
    margin-top: 0px !important;

}


.per_day {
    display: flex !important;
    align-items: flex-start !important;
    flex-wrap: wrap !important;
    justify-content: start !important;
}

.add_vehicle {
    display: flex;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    flex-wrap: wrap;
}

.input_feild {
    border: 1px solid #cecece !important;
    line-height: 1.5 !important;
    padding: 8px 16px 8px 16px !important;
    height: 47.5px !important;

}

.input_feild div {
    border: none;


}

.react-date-picker__calendar {
    bottom: 45px !important;
}

.react-date-picker__calendar .react-calendar {
    border: 1px solid gray;
    margin-top: 12px !important;
    margin-bottom: 12px !important;
}


.error-msg {
    padding: 18px 0;
}

.copyright {
    z-index: 0 !important;
}
/* .address-createpartner .css-yk16xz-control {
    border-radius: 1rem !important;
    height: 3.5rem !important;
    border: 1px solid #cecece !important;
}
.address-createpartner .css-yk16xz-control:focus {
    outline: none;
}
.address-createpartner .css-yk16xz-control:hover {
    outline: none;
    border-radius: 1rem !important;
    height: 3.5rem !important;
    border: 1px solid #cecece !important;
} */
.address-createpartner .css-1pahdxg-control:hover {
    border: noen !important;
}
.address-createpartner .css-1pahdxg-control {
  border-color: #cecece !important ;
  border-radius: 1rem !important;
  border-style: solid !important;
  box-shadow: none !important;
  height: 3.5rem !important;

} 
.address-createpartner .css-yk16xz-control {
    border-radius: 1rem !important;
    height: 3.5rem;
    background-color: #f8f9fa !important;
}
.delete-contact-input {
    display: flex;
    width: 100% !important;
    align-items: center;
    justify-content: space-between;
    width: 100% !important;
}
.checkbox{
    width: 20px;
    height: 20px;
    margin: 0 7px;
}

.table-striped > tbody > tr:nth-of-type(even) > *, .table-modern > tbody > tr:nth-of-type(even) > * {
    --bs-table-accent-bg: white var(--bs-table-striped-bg) !important;
}
.delete-card-inner  {
    width: 33.333% !important;
    padding: 19px !important;
}
.delete_btn_addpartner {
    margin: 0 7px;
    width: 45px !important;
    height: 37px !important;
}
.changerates:hover {
    background-color:#dfdfdfeb;
    --bs-table-hover-bg: none;
}
.editpartner-deletebtn {
  width: 90% !important;
}
.surcharge_daily {
    text-align: center;
}
.surcharge-seprator {
 border: 1px solid black !important;
 height: 21px !important;
}
.edit_rate_align {
    display: flex;
    align-items: center;
}
.ic-delete-btn {
    margin-left: 9px !important;
}
.pdf_btn {
    border: 1px solid #cecece !important;
    background-color: #f8f9fa;
    padding: 8px 16px 8px 16px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    height: 3.5rem;
}
.download-pdf {
    margin: 10px;
    cursor: pointer;
    white-space: nowrap;
}
.text-start {
    width: 100%;
    padding: 19px;
}
.flexbtn {
    display: flex;
}


.deatil-text strong {
    font-weight: 700;
    color: rgb(59, 43, 75); 
}
.deatil-text {
    padding: 19px;
}
.deatil-text p {
    font-weight: 700;
    color: rgb(109, 105, 114);
    margin-bottom: 8px;
}
.title-wrapper {
    border-bottom: 1px solid gray;
    padding: calc(1.275rem + 0.3vw) calc(1.275rem + 0.3vw);
}
.title-h5 {
    margin: 0;
    font-weight: 700;
    font-size: 1.25rem;
}
.card-body {
    padding: calc(1.275rem + 0.3vw) calc(1.275rem + 0.3vw);
}
.vehicle-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.revenu-checkimg {
    position: relative;
    padding-top: 220px;
    width: 190px !important;
    margin: 0 15px 0 0;
    display: inline-block;
}
.revenu-checkimg  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.checkimg-wrapper {
    margin-bottom: 50px;
}
.checktitle {
    margin:  0 0 15px 0;
    padding: 0;
}
.checking-scrollerwrapper {
    white-space: nowrap;
    overflow-x : auto;
}
.checking-scrollerwrapper:hover {
}